<template>
  <loader v-bind="{ loading }">
    Projects
  </loader>
</template>
<script>
export default {

  data: () => ({
    loading: false
  })

}
</script>